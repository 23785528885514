<template lang="pug">
  .container
    .row
      .col-xs-12
        AlertBase(v-if="flashMessage" :type="flashMessage.variant")
          p(v-for="message of flashMessage.messages" ) {{message}}
        FlashMessages.mb-xs(v-if="hasFlashMessage")
        h1.heading-3.text-center.my-md クーポン
        .contents
          .message(v-if="concatCoupons.length === 0")
            p.paragraph ご利用可能なクーポンがありません。
          template(v-if="concatCoupons.length > 0")
            CouponList(:coupons="concatCoupons" @receivedCoupon="receivedCoupon")
            p.paragraph クーポンはご注文時にご指定頂けます。利用店舗とご利用可能金額をご確認の上お買い物をお楽しみ下さい。
</template>

<script>
import { GetUserCoupons } from '../../graphqls/UserCoupons'
import { GetCoupons } from '../../graphqls/Coupons'
import GlobalMixin from '../../mixin/GlobalMixin'
import ButtonBase from '../../components/v1/button/ButtonBase'
import CouponList from '../../components/v1/coupon/CouponList'
import FlashMessages from "../../components/v1/alert/FlashMessages";
import AlertBase from "../../components/v1/alert/AlertBase";


export default {
  name: 'CouponIndex',
  mixins: [GlobalMixin],
  components: {
    AlertBase,
    ButtonBase,
    CouponList,
    FlashMessages,
  },
  data() {
    return {
      havingCoupons: [],
      concatCoupons: [],
      flashMessage: null
    }
  },
  metaInfo() {
    return {
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: this.$_itemListElement
        }
      }]
    }
  },
  async mounted() {
    this.loadingStart()
    await this.getUserCoupons()
    await this.getCoupons()
    this.setBreadcrumbs({ breadcrumbs: [
      {
        path: '/',
        name: 'TOP',
      },
      {
        path: '/mypage/',
        name: 'マイページ',
      },
      {
        path: '/mypage/coupon/',
        name: 'クーポン',
      },
    ]})
    this.loadingStop()
  },
  methods: {
    async getUserCoupons() {
      const { data } = await this.$apollo
      .query({
        query: GetUserCoupons,
        client: 'apiClient'
      })
      .catch(error => {
        this.$_redirect_internal_server_error(error)
      })
      const userCoupons = data.userCoupons.items
      for (const userCoupon of userCoupons) {
        let havingCoupon = this.havingCoupons.find(coupon => coupon.couponId === userCoupon.coupon.id)
        if (havingCoupon) {
          havingCoupon.totalCount++
        } else {
          this.havingCoupons.push({
            couponId: userCoupon.coupon.id,
            couponCode: userCoupon.coupon.code,
            couponName: userCoupon.coupon.name,
            thumbnail: userCoupon.displayThumbnail,
            benefits: userCoupon.displayBenefit,
            availableShopCode: userCoupon.availableAdminShop?.code,
            availableShopName: userCoupon.displayAvailableShopname,
            expirationDays: userCoupon.displayExpirationDays,
            expirationDate: userCoupon.displayExpirationDate,
            minUseAmount: userCoupon.displayMinUseAmount,
            remainingCount: userCoupon.remainingCount,
            isSoldout: userCoupon.isSoldout,
            hasCoupon: true,
            totalCount: 1,
          })
        }
      }
    },
    async getCoupons() {
      const { data } = await this.$apollo
      .query({
        query: GetCoupons,
        client: 'apiClient'
      })
      .catch(error => {
        this.$_redirect_internal_server_error(error)
      })
      const notHavingCoupons = data.coupons.items.map(coupon => ({
        couponId: coupon.id,
        couponCode: coupon.code,
        couponName: coupon.name,
        thumbnail: coupon.displayThumbnail,
        benefits: coupon.displayBenefit,
        availableShopCode: coupon.availableAdminShop?.code,
        availableShopName: coupon.displayAvailableShopname,
        expirationDays: coupon.displayExpirationDays,
        expirationDate: coupon.displayExpirationDate,
        minUseAmount: coupon.displayMinUseAmount,
        remainingCount: coupon.remainingCount,
        isSoldout: coupon.isSoldout,
        hasCoupon: false,
      }))
      // 取得済みクーポンと未取得クーポンを連結
      this.concatCoupons = this.havingCoupons.concat(notHavingCoupons)
    },
    async receivedCoupon(isSuccess) {
      this.loadingStart();
      await this.getUserCoupons();
      await this.getCoupons();
      this.flashMessage = null
      if (isSuccess) {
        this.$scrollTo("body")
        this.flashMessage = {
          variant: 'success',
          messages: ['クーポンを獲得しました。'],
        };
      }
      this.loadingStop();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/stylesheets/v1/styles";
.container {
  margin: auto;
  font-size: 14px;
  > .row {
    > .col-xs-12 {
      > .contents {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-self: stretch;
        background-color: $ivory-100;
        max-width: 960px;
        margin: auto;
        padding: 16px;
        @include mq-down() {
          padding: 8px;
        }
        > .message {
          padding: 16px;
          background-color: $white;
        }
        > .button {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
