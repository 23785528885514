<template lang="pug">
  .coupon-show.mb-md
    AlertBase.mb-xs(v-if="validateErrors.length > 0" type="error")
      p(v-for="message of $_validationErrorMessage") {{ message }}
    HeadingRoof.mt-xs.mb-md(
      v-if="coupon.title || coupon.subTitle"
      :title="coupon.title",
      :subtitle="coupon.subTitle"
    )
    ContainerFrame
      ImgResponsive(:src="coupon.mainImageSet.lgImage")
      HeadingArticle.my-md クーポン内容
      VueMarkdown.paragraph.markdown(v-if="coupon.description" :source="coupon.description")
      TableBase.mt-md.mb-sm
        tbody
          tr
            th.text-left クーポン名
            td {{ coupon.name }}
          tr
            th.text-left プレゼント内容
            td 
              | 商品代金
              span {{ coupon.displayMinUseAmount }}
              | (税込)以上でご利用できる
              span {{ coupon.displayBenefit }}
              | クーポンを
              span {{ coupon.userIssueNumber }}枚
              | プレゼント
          tr
            th.text-left 利用可能店舗
            td {{ coupon.availableAdminShopName }}
          tr
            th.text-left クーポン発行期間
            td
              template(v-if="coupon.publishedStartAt") {{ coupon.displayPublishedStartAt }}
              template(v-if="coupon.publishedStartAt || coupon.publishedEndAt") ~
              template(v-if="coupon.publishedEndAt") {{ coupon.displayPublishedEndAt }}
          tr
            th.text-left クーポン利用期間
            td
              template(v-if="coupon.expirationStartAt") {{ coupon.displayExpirationStartAt }}
              template(v-if="coupon.expirationStartAt || coupon.expirationEndAt") ~
              template(v-if="coupon.expirationEndAt") {{ coupon.displayExpirationEndAt }}
          //tr
            th.text-left 発行枚数
            td 先着{{ coupon.totalIssueNumber }}名様限定
              br
              | ※ 数量に達した場合、発行期間内でもクーポンの受取が出来なくなります。
          tr
            th.text-left ご利用枚数
            td 先着{{ coupon.totalAvailableNumber }}枚限定
              br
              | ※ クーポン利用期間内でも、利用枚数が上限に達した場合はご利用いただけません。
          tr(v-if="coupon.minOrderNum || coupon.maxOrderNum")
            th.text-left 発行条件
            td 
              | {{ coupon.paymentAdminShop.name }}で
              template(v-if="coupon.minOrderNum == 0 && coupon.maxOrderNum == 0") 初めてお買い物するお客さま
              template(v-else) ご購入履歴が
                template(v-if="coupon.minOrderNum") {{ coupon.minOrderNum }}回以上
                template(v-if="coupon.maxOrderNum") {{ coupon.maxOrderNum }}回以下
                | のお客様
          tr
            th.text-left 注意事項
            td
              ul
                li * クーポンを受け取るには会員登録が必要になります。
                template(v-if="coupon.maxOrderNum")
                  template(v-if="coupon.maxOrderNum == 0")
                    li * 過去にたべるとくらすとでご注文いただいた方はクーポンを取得できません。
                  
                li * 他のクーポンと併用はできません。
                li * キャンペーンは予告なく変更または終了となる場合があります。
                li * ご注文をキャンセルされた場合、クーポンを再度利用することはできません。
                li * ご注文時、配送先の複数設定は出来ません。
                li * 会員様1名1注文につき商品代金{{ coupon.displayMinUseAmount }}(税込)以上のご購入でクーポンのご利用が可能です。
                li * 定期購入のご注文の場合、クーポンを利用することはできません。
                template(v-if="coupon.benefitType == 'free_shipping_benefit' || coupon.benefitType == 'half_shipping_price_benefit'")
                  li * すでに送料無料対象の商品に関してはこのクーポンは適応されません。
                  li * ご注文時にカート内で発生した送料のみが適応となります。
                  li * 追加で発生した場合の送料は、基本的にお客様のご負担となります。
      template(v-if="coupon.isSoldout")
        p.error-notice ※ クーポン利用枚数が上限に達したため、クーポンの発行は終了しました。
        ButtonBase(:block="true" :disabled="true" variant="primary") 利用可能人数に達しました
      template(v-else)
        template(v-if="coupon.isPublished")
          template(v-if="!currentUser")
            ButtonBase(:block="true" href="/regist/" variant="primary") 会員登録(ログイン)してクーポンを受け取る
          template(v-else)
            ButtonBase(:block="true" @click="receiveCoupon()" variant="primary") クーポンを受け取る
        template(v-else)
          ButtonBase(:block="true" :disabled="true" variant="primary") クーポンを受け取る
          p.notice.mt-xxxs ※ クーポン発行期間対象外です
</template>

<script>
  import { mapActions } from 'vuex'
  import VueMarkdown from 'vue-markdown'
  import { GetCouponForApi, CreateCouponForApi } from '../../graphqls/Coupons'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import HeadingArticle from '../../components/v1/typography/HeadingArticle'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ContainerFrame from '../../components/v1/container/ContainerFrame'
  import ImgResponsive from '../../components/image/ImgResponsive'
  import TableBase from '../../components/v1/table/TableBase'
  import HeadingRoof from '../../components/v1/heading/HeadingRoof'

  export default {
    name: 'CouponShow',
    mixins: [GlobalMixin],
    components: {
      HeadingArticle,
      AlertBase,
      ButtonBase,
      ContainerFrame,
      ImgResponsive,
      TableBase,
      HeadingRoof,
      VueMarkdown,
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        coupon: {
          id: "",
          code: "",
          name: "",
          title: "",
          subTitle: "",
          description: "",
          minUseAmount: "",
          displayMinUseAmount: "",
          displayBenefit: "",
          publishedStartAt: "",
          publishedEndAt: "",
          displayPublishedStartAt: "",
          displayPublishedEndAt: "",
          expirationStartAt: "",
          expirationEndAt: "",
          displayExpirationStartAt: "",
          displayExpirationEndAt: "",
          userIssueNumber: "",
          availableAdminShopName: "",
          totalIssueNumber: "",
          minOrderNum: "",
          maxOrderNum: "",
          benefitType: "",
          isSoldout: "",
          isPublished: "",
          mainImageSet: { 
            lgImage: "",
          },
          paymentAdminShop: {
            name: "",
          },
          displayMetaTitle: "",
        },
      }
    },
    computed: {
      currentUser() {
        return this.$store.getters.currentUser.user
      },
    },
    methods: {
      ...mapActions([
        'setFlashMessage',
      ]),
      async fetchCoupon () {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          query: GetCouponForApi,
          variables: {
            code: this.$route.params.code
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.$_redirect_internal_server_error(error)
        })
        this.coupon = data.coupon
        // パンくずリストを設定
        this.setBreadcrumbs({ breadcrumbs: [
          {
            path: '/',
            name: 'TOP',
          },
          {
            path: `/coupon/${this.coupon.code}/`,
            name: this.coupon.displayMetaTitle,
          },
        ]})
        this.loadingStop()
      },
      async receiveCoupon() {
        this.loadingStart()
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateCouponForApi,
          variables: {
            input: {
              code: this.$route.params.code
            }
          },
          client: 'apiClient'
        })
        .catch(error => {
          this.$_redirect_internal_server_error(error)
        })
        // バリデーションエラーが発生した場合
        const errors = data.createCoupon.errors
        if (errors) {
          this.$_parseError(errors)
          this.$scrollTo("body")
          this.loadingStop()
          return
        }
        // 成功メッセージを格納して注文履歴画面に遷移
        this.setFlashMessage({
          variant: 'success',
          messages: ['クーポンを獲得しました。'],
        })
        this.$router.push({ name: 'mypage-coupon-index' });
        this.loadingStop()
      },
    },
    mounted: function () {
      // クーポン情報の取得
      this.fetchCoupon()
      // サイド表示
      this.showSide()
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
  .coupon-show {
    .description {
      color: $black-400;
      margin-bottom: 32px;
    }
    span {
        font-weight: bold;
    }
    .notice {
      font-size: 85%;
      text-align: center;
    }
    .error-notice {
      font-size: 85%;
      text-align: center;
      padding:0.5rem;
      color: $red-400;
      margin: 1rem auto;
      border: 1px solid $red-400;
    }
  }
</style>

<style lang="scss">
  @import "../../../../assets/stylesheets/v1/styles";
  .coupon-show {
    .markdown {
      p:not(:first-child) {
        @include margin-top(sm);
      }
      .red_text {      
        color: $red-300;
        font-weight: bold;
      }
      .link-primary {
        color: $olive-400;
        text-decoration: underline;
      }
      dl {
        dt {
          line-height: 2;
          font-weight: bold;
          margin-top: 8px;
        }
        dd {
          line-height: 2;
        }
      }
      .linkBtn {
        padding: 12px;
        display: inline-flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        font-family: $font-family-sans;
        @include font-size(xs);
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        line-height: $line-height-snug;
        color: $black-400;
        border: 1px solid $gray-300;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.5s ease;
        
        @include mq-down(md) {
          width: 100%;
        }
      } 
      .linkBtn:hover {
        background: $gray-100;

      }
      .linkBtn.primary {
        color: $white;
        border: none;
        background: $olive-300;

        @include mq-down(md) {
          width: 100%;
        }
      } 
      .linkBtn.primary:hover {
        background: $olive-400;
      }
    }
  }
</style>