<template lang="pug">
  .column-item
    time.date {{ column.postedAt | moment('YYYY/MM/DD') }}
    h4.title.mb-xxs
      a(:href="`/columns/${column.columnCategoryId}/${column.customUrl}/`") {{ column.title }}
    VueMarkdown(:breaks="false").paragraph.mb-xxs {{ description }}
    a.more.anker-link.mb-xs(:href="`/columns/${column.columnCategoryId}/${column.customUrl}/`") 記事の続きを見る

    swiper.swiper(:options="swiperOption")
      swiper-slide(v-for="imageColumn in column.imageColumnBodies" :key="imageColumn.id")
        img(:src="imageColumn.imageSet.xsImage" width="100%" height="auto")
      .swiper-button-prev(slot="button-prev")
      .swiper-button-next(slot="button-next")
</template>

<script>
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import VueMarkdown from 'vue-markdown'


  export default {
    name: 'ColumnItem',
    components: {
      Swiper,
      SwiperSlide,
      VueMarkdown
    },
    directives: {
      swiper: directive
    },
    props: {
      column: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    data() {
      return {
        swiperOption: {
          loop: true,
          slidesPerView: '3',
          centeredSlides: true,
          spaceBetween: 5,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    },
    computed: {
      description() {
        return this.column.firstTextColumnBody ? this.column.firstTextColumnBody.description : this.column.metaDescription
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .column-item {
    > .date {
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(xs);
      line-height: $line-height-snug;
      color: $black-400;
    }

    > .title {
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      @include font-size(md);
      line-height: $line-height-snug;
      > a {
        color: $black-400;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $olive-300;
        }
      }
    }

    > .more {
      display: block;
      text-align: right;
      @include font-size(sm);
    }

    .swiper {
      .swiper-slide {
        .img-responsive {
          margin: 0 auto;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        color: #DBD9D3;
        @include mq-down(){
          display: none;
        }
        &:focus {
          outline: none;
        }
        &::after {
          font-size: 30px;
        }
      }
      .swiper-button-prev {
        left: 15px;
      }
      .swiper-button-next {
        right: 15px;
      }
    }
  }
</style>
