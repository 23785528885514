<template lang="pug">
  .sidemenu-shop(v-if="currentShop.id")
    .item.header
      a(:href="`/shop/${currentShop.code}/`")
        img.logo(v-if="currentShop.logoImageSet" :src="currentShop.logoImageSet.mdSqImage" :alt="`${currentShop.name}ロゴ`")
      h3.heading
        span {{ currentShop.name }}
        span お取り寄せ通販
    .item
      .shopping-guide
        .title 送料・配送
        .content
          p クレジットカードでのご購入の場合、ご注文確定後{{ currentShop.delivery.maxShippingPreparerPeriod }}営業日以内に出荷 銀行振り込みの場合、入金確認後{{ currentShop.delivery.maxShippingPreparerPeriod }}営業日以内に出荷
          p(v-if="currentShop.specifiedDeliveryDateTerm")
            | 日時指定はご注文日 {{ currentShop.specifiedDeliveryDateTerm }}日 ~ 60日以内
    .item
      .shopping-guide
        .title お支払い方法
        .content
          ul.payments
            li.payment(v-if="currentShop.isCreditCardAvailable")
              p クレジットカード
              ul.credits
                li.credit
                  include ../../../../../assets/images/icon/cc-visa-brands.svg
                  span.name VISA
                li.credit
                  include ../../../../../assets/images/icon/cc-mastercard-brands.svg
                  span.name MASTER
                li.credit(v-if="currentShop.creditBrandsAcceptedList.includes('JCB')")
                  include ../../../../../assets/images/icon/cc-jcb-brands.svg
                  span.name JCB
                li.credit(v-if="currentShop.creditBrandsAcceptedList.includes('Discover')")
                  include ../../../../../assets/images/icon/cc-diners-club-brands.svg
                  span.name DINERS
                li.credit(v-if="currentShop.creditBrandsAcceptedList.includes('American Express')")
                  include ../../../../../assets/images/icon/cc-amex-brands.svg
                  span.name AMEX
            li.payment(v-if="currentShop.isCodAvailable")
              p 代金引換（{{ currentShop.minCodFee.feeInTax | numberToCurrency }}~）
            li.payment(v-if="currentShop.isConvenienceAvailable")
              p コンビニ後払い
            li.payment(v-if="currentShop.isBankTransferAvailable")
              p 銀行振込
              p.text-xxs PayPay銀行・ゆうちょ銀行
          p.detail(@click="openPaymentModal()")
            IconClone(:size="14")
            | 詳しくはこちら
    .item
      .shopping-guide
        p.title キャンセル・返品・交換
        .content
          p 不良・欠陥・誤商品が届いた場合、お届けから７日以内（生鮮食品は翌日まで）にご連絡ください。
          p.detail(@click="openReturnPolicyModal()")
            IconClone(:size="14")
            | 詳しくはこちら
    .item
      a.link(:href="`/shop/${currentShop.code}/law/`") 特定商取引法
    .item
      a.link(:href="`/shop/${currentShop.code}/contact/`") {{ currentShop.name }}へお問合せ

    ModalPayment(ref="modalPayment" :adminShop="currentShop")
    ModalReturnPolicy(ref="modalReturnPolicy" :adminShop="currentShop")
</template>

<script>
  import { mapGetters } from 'vuex'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import IconClone from '../icon/IconClone'
  import ModalPayment from '../modal/ModalPayment'
  import ModalReturnPolicy from '../modal/ModalReturnPolicy'

  export default {
    name: 'SideMenuShop',
    mixins: [GlobalMixin],
    components: {
      IconClone,
      ModalPayment,
      ModalReturnPolicy
    },
    computed: {
      ...mapGetters([
        'currentShop'
      ])
    },
    methods: {
      openPaymentModal() {
        this.$refs.modalPayment.open()
      },
      openReturnPolicyModal() {
        this.$refs.modalReturnPolicy.open()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.sidemenu-shop {
  width: 100%;
  background: $ivory-100;
  padding: #{map-get($padding-margin-pc, xxxs)}px;

  > .item {
    margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;

    &.header {
      @include mq-down(md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .logo {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
    @include mq-down(md) {
      width: 50px;
      margin-right: #{map-get($padding-margin-pc, xxxs)}px;
      margin-bottom: #{map-get($padding-margin-pc, zero)}px;
    }
  }

  .heading {
    font-family: $font-family-serif;
    font-weight: $font-weight-bold;
    font-style: $font-style-not-italic;
    font-size: #{map-get($font-size-pc, xs)}px;
    line-height: $line-height-snug;
    color: $black-400;
    text-align: center;
  }

  .shopping-guide {
    background: $white;
    padding: #{map-get($padding-margin-pc, xxxs)}px;

    > .title {
      font-family: $font-family-sans;
      font-weight: $font-weight-bold;
      font-style: $font-style-not-italic;
      font-size: #{map-get($font-size-pc, xs)}px;
      line-height: $line-height-normal;
      color: $black-400;
      text-align: center;
      border-bottom: 1px solid $ivory-100;
      padding-bottom: #{map-get($padding-margin-pc, xxxs)}px;
      margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
    }

    > .content {
      font-family: $font-family-sans;
      font-weight: $font-weight-regular;
      font-style: $font-style-not-italic;
      font-size: #{map-get($font-size-pc, xs)}px;
      line-height: $line-height-normal;
      color: $black-400;

      .payment {
        margin-bottom: #{map-get($padding-margin-pc, xxxs)}px;
      }

      .credits {
        display: flex;
        width: 100%;
        > .credit {
          margin-right: #{map-get($padding-margin-pc, xxxs)}px;
          > svg {
            display: block;
            width: 28px;
            margin: 0 auto;
          }
          > .name {
            font-size: #{map-get($font-size-pc, xxxs)}px;
            display: block;
            text-align: center;
          }
        }
      }

      > .detail {
        display: inline-flex;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
        font-family: $font-family-sans;
        font-weight: $font-weight-regular;
        font-style: $font-style-not-italic;
        font-size: #{map-get($font-size-pc, xs)}px;
        line-height: $line-height-normal;
        color: $black-400;
        &:hover {
          color: $olive-300;
        }
        .icon-base {
          margin-right: #{map-get($padding-margin-pc, xxxs)}px;
        }
      }
    }
  }

  .link {
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    font-size: #{map-get($font-size-pc, xs)}px;
    line-height: $line-height-normal;
    color: $black-400;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $olive-300;
    }
  }
}
</style>
