<template lang="pug">
  .coupon-item(:class="{ unobtained: !coupon.hasCoupon || coupon.isSoldout }")
    .thumbnail
      img(:src="coupon.thumbnail" :alt="coupon.couponName")
    .desc
      .remaining(v-if="coupon.hasCoupon && coupon.totalCount >= 2") {{ `残り${coupon.totalCount}回分` }}
      .title
        p.benefits {{ coupon.benefits }}
        p {{ coupon.couponName }}
      .info
        .info-item
          include ../../../../../assets/images/icon/shop.svg
          span {{ coupon.availableShopName }}
        .info-item
          include ../../../../../assets/images/icon/clock.svg
          span.bold(v-if="coupon.remainingCount") 残り{{ coupon.remainingCount }}枚利用可能
          span ・
          span.bold(v-if="coupon.expirationDays") {{ coupon.expirationDays }}
          span {{ coupon.expirationDate }}
        p {{ `※${coupon.minUseAmount}以上ご購入で利用可` }}
      template(v-if="coupon.hasCoupon")
        a(:href="`/coupon/${coupon.couponCode}`") クーポンに関する注意事項を読む
        a(v-if="coupon.availableShopCode" :href="`/shop/${coupon.availableShopCode}/items`") 対象商品を見る
        a(v-else href="/") トップで対象商品を見る
    .button(v-if="!coupon.hasCoupon")
      ButtonBase(variant="primary" @click="receiveCoupon()") クーポンを取得する
    .button(v-if="coupon.isSoldout")
      ButtonBase(variant="primary" :disabled="true") 利用可能人数に達しました。
</template>

<script>
import ButtonBase from '../button/ButtonBase'
import {CreateCouponForApi} from "../../../graphqls/Coupons";
import GlobalMixin from '../../../mixin/GlobalMixin'
import {mapActions} from "vuex";


  export default {
    name: 'CouponItem',
    mixins: [GlobalMixin],
    components: {
      ButtonBase,
    },
    props: {
      coupon: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    methods: {
      ...mapActions([
        'setFlashMessage',
      ]),
      async receiveCoupon() {
        this.loadingStart()
        const { data } = await this.$apollo
            .mutate({
              mutation: CreateCouponForApi,
              variables: {
                input: {
                  code: this.coupon.couponCode
                }
              },
              client: 'apiClient'
            })
            .catch(error => {
              this.$_redirect_internal_server_error(error)
            })
        // バリデーションエラーが発生した場合
        const errors = data.createCoupon.errors
        if (errors) {
          this.$_parseError(errors)
          this.$scrollTo("body")
          this.loadingStop()
          this.$emit('receivedCoupon', false);
          return
        }
        this.$emit('receivedCoupon', true);
        this.loadingStop()
      },
    },
    mounted() {
      this.setFlashMessage({
        variant: 'success',
        messages: ['ありがとうございました。レビューの投稿が完了しました。'],
      })

    }
  }
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
  .coupon-item {
    position: relative;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background-color: $white;
    color: $black-400;
    > .thumbnail {
      width: 96px;
      height: auto;
      > img {
        width: 100%;
      }
    }
    > .desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      font-size: 14px;
      position: relative;
      p {
        line-height: $line-height-snug;
      }

      > .remaining {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        padding: 4px 6px;
        background: $black-300;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: $line-height-none;
        text-align: left;
        color: $white;
      }

      > .title {
        > .benefits {
          color: #A46761;
          font-size: 18px;
          font-weight: bold;
          line-height: $line-height-tight;
        }
      }
      > .info {
        > .info-item {
          display: flex;
          align-items: center;
          line-height: $line-height-tight;
          > svg {
            width: 16px;
            height: auto;
            margin-right: 4px;
            > path {
              fill: $black-400;
            }
          }
          > .bold {
            font-weight: bold;
            margin-right: 2px;
          }
        }
      }
      > a {
        color: $olive-400;
        text-decoration: none;
      }
    }
    &.unobtained {
      border-radius: 4px;
      border: 1px solid $olive-400;
      > .button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }
    }
    &.unobtained::before {
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      opacity: 0.8;
      content: '';
      z-index: 1;
    }
  }
</style>
