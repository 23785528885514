<template lang="pug">
  .coupon-list
    CouponItem(
      v-for="coupon in coupons"
      :key="coupon.couponId"
      :coupon="coupon"
      @receivedCoupon="receivedCoupon"
    )
</template>

<script>
  import CouponItem from '../coupon/CouponItem'

  export default {
    name: 'CouponList',
    components: {
      CouponItem,
    },
    props: {
      coupons: {
        type: Array,
        required: false,
        default: () => []
      },
    },
    methods: {
      receivedCoupon(isSuccess) {
        this.$emit('receivedCoupon', isSuccess);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";
  .coupon-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
</style>
