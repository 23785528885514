import gql from 'graphql-tag'

export const GetUserCoupons = gql`
query {
  userCoupons {
    items {
      coupon {
        id
        code
        name
      }
      displayThumbnail
      displayBenefit
      displayAvailableShopname
      displayExpirationDays
      displayExpirationDate
      displayMinUseAmount
      remainingCount
      isSoldout
      availableAdminShop {
        id
        code
        name
      }
    }
  }
}`
